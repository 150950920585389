export const PROVINCE_NAMES = [
    {
        "id": 1,
        "name": "Koshi Pradesh - 1"
    },
    {
        "id": 2,
        "name": "Madhesh Pradesh - 2"
    },
    {
        "id": 3,
        "name": "Bagmati Pradesh - 3"
    },
    {
        "id": 4,
        "name": "Gandaki Pradesh - 4"
    },
    {
        "id": 5,
        "name": "Lumbini Pradesh - 5"
    },
    {
        "id": 6,
        "name": "Karnali Pradesh - 6"
    },
    {
        "id": 7,
        "name": "Sudurpashchim Pradesh - 7"
    }
];