import "./style.css"
import { Card, CardBody, CardTitle, CardImg, ListGroup, ListGroupItem } from 'reactstrap';
import { PROVINCE_NAMES } from "../../Constants/Province";
import styled from 'styled-components';

const CustomCard = styled(Card)`
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  width: 235px;
  background-color: white;
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1000;

  @media (max-width: 600px) {
    position: absolute;
    bottom: 70px;
    left: 10px;
  }
`;

const InfoCard = ({ feature }) => {
  return (
    <>
      <div className="display-web">
        <CustomCard
          // className="info-card-web"
          // style={{
          //   position: 'absolute',
          //   bottom: '10px',
          //   left: '10px',
          //   zIndex: 1000,
          // }}
        >
          <CardImg
            className="card-image"
            alt="Card image cap"
            src={`/images/province_${feature?.PROVINCE}.webp` || "/images/province_1.webp"}
            style={{
              width: "100%",
              height: "121px",
            }}
          />
          <CardBody className="pt-3 pb-2">
            <CardTitle className="fs-6 mb-0">
              {PROVINCE_NAMES?.find(province => province?.id === feature?.PROVINCE)?.name || "---"}
            </CardTitle>
          </CardBody>
          {feature?.DISTRICT &&
            <ListGroup flush>
              {feature?.DISTRICT && <ListGroupItem key="district">
                <strong>District: </strong>{`${feature?.DISTRICT}`}
              </ListGroupItem>}
              {feature?.PALIKA && <ListGroupItem key="palika">
                <strong>Palika: </strong>{`${feature?.PALIKA} ${feature?.TYPE}`}
              </ListGroupItem>}
              {feature?.WARD && <ListGroupItem key="ward">
                <strong>Ward No. </strong>{`${feature?.WARD}`}
              </ListGroupItem>}
            </ListGroup>
          }
        </CustomCard>
      </div>
    </>
  );
};

export default InfoCard;