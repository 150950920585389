import React from 'react';
import { Button, Offcanvas, OffcanvasBody } from 'reactstrap';
import "./style.css"
import { X } from 'react-feather';

function Sidebar({ isOpen, toggle, ...args }) {

    return (
        <div>
            <Offcanvas {...args}
                isOpen={isOpen}
                toggle={toggle}
                placement="start"
                style={{ width: "320px" }}
            >
                <div className='text-dark d-flex align-items-center justify-content-between pb-0'>
                    <h5 className="p-3 mb-0">Sidebar</h5>
                    <Button
                        color="none"
                        outline
                        onClick={toggle}
                    >
                        <X color='white' onClick={toggle} />
                    </Button>
                </div>
                {/* <X /> */}
                <OffcanvasBody>
                    {/* Content goes here */}
                </OffcanvasBody>
            </Offcanvas>
        </div>
    );
}

export default Sidebar;
