import React, { useState } from 'react';
import { MapContainer, ZoomControl, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import "./style.css"
import WardLayer from '../../geoJson/New/4_WARD.json';
import PalikaLayer from '../../geoJson/New/3_PALIKA.json';
import DistrictLayer from '../../geoJson/New/2_DISTRICT.json';
import ProvinceLayer from '../../geoJson/New/1_PROVINCE.json';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import Search from '../Search/Search';
import CustomGeoJSON from '../CustomGeoJSON/CustomGeoJSON';
import InfoCard from '../InfoCard/InfoCard';
import LocationMarker from '../LocationMarker/LocationMarker';
import GetMyLocationButton from '../LocationMarker/GetMyLocationButton';

function getGeoJsonStyle(feature) {
  let baseStyle = {
    stroke: true,
    fill: true,
    fillOpacity: 0.05,
    weight: 1,
    opacity: 0.3,
  };

  switch (feature.properties.TYPE) {
    // case PALIKA_TYPES.GAUN:
    //   return { ...baseStyle, color: "#2A9D8F" };
    // case PALIKA_TYPES.NAGAR:
    //   return { ...baseStyle, color: "#E76F51" };
    // case PALIKA_TYPES.UPA_MAHA:
    //   return { ...baseStyle, color: "#F4A261" };
    // case PALIKA_TYPES.MAHA:
    //   return { ...baseStyle, color: "#4E86A8" };
    // case PALIKA_TYPES.WILDLIFE:
    //   return { ...baseStyle, color: "#264653" };
    default:
      return {
        ...baseStyle,
        fillColor: "#577BFF"
      };
  }
}

// eslint-disable-next-line no-unused-vars
const PALIKA_TYPES = {
  MAHA: "Mahanagarpalika",
  UPA_MAHA: "Upamahanagarpalika",
  GAUN: "Gaunpalika",
  NAGAR: "Nagarpalika",
  WILDLIFE: "Wildlife Reserve",
}

const MapView = ({ toggleSidebar }) => {
  const position = [27.644453, 85.327706];
  const [currentFeature, setCurrentFeature] = useState(null);

  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedPalika, setSelectedPalika] = useState(null);
  const [selectedWard, setSelectedWard] = useState(null);

  const [currentPosition, setCurrentPosition] = useState(null);
  const [searchPosition, setSearchPosition] = useState(null);

  const [searchedName, setSearchedName] = useState(""); 

  const nepalBounds = [
    [32.603770, 75.058],
    [24.245732, 91.022631]
  ];

  return (
    <MapContainer
      center={position}
      zoom={7}
      minZoom={7}
      zoomControl={false}
      style={{
        height: '100vh',
        width: '100vw',
        fontFamily: `Roboto, 'Noto Sans Devanagari UI', Arial, sans-serif`,
      }}
      bounceAtZoomLimits={true}
      bounds={nepalBounds}
      maxBounds={nepalBounds}
    >
      <Search toggleSidebar={toggleSidebar} setSearchPosition={setSearchPosition} setSearchedName={setSearchedName} />
      <ZoomControl position='bottomright' className="zoom" />
      {currentFeature && <InfoCard feature={currentFeature} />}
      <CustomGeoJSON
        layerName={"ProvinceLayer"}
        data={ProvinceLayer}
        style={getGeoJsonStyle}
        onHover={setCurrentFeature}
        setSelectedProvince={setSelectedProvince}
        setSelectedDistrict={setSelectedDistrict}
        setSelectedPalika={setSelectedPalika}
        setSelectedWard={setSelectedWard}
      />
      <CustomGeoJSON
        layerName={"DistrictLayer"}
        data={DistrictLayer}
        style={getGeoJsonStyle}
        onHover={setCurrentFeature}
        selectedProvince={selectedProvince}
        setSelectedProvince={setSelectedProvince}
        setSelectedDistrict={setSelectedDistrict}
        setSelectedPalika={setSelectedPalika}
        setSelectedWard={setSelectedWard}
      />
      <CustomGeoJSON
        layerName={"PalikaLayer"}
        data={PalikaLayer}
        style={getGeoJsonStyle}
        onHover={setCurrentFeature}
        selectedDistrict={selectedDistrict}
        setSelectedProvince={setSelectedProvince}
        setSelectedDistrict={setSelectedDistrict}
        setSelectedPalika={setSelectedPalika}
        setSelectedWard={setSelectedWard}
      />
      <CustomGeoJSON
        layerName={"Wardlayer"}
        data={WardLayer}
        style={getGeoJsonStyle}
        onHover={setCurrentFeature}
        selectedPalika={selectedPalika}
        selectedWard={selectedWard}
        setSelectedProvince={setSelectedProvince}
        setSelectedDistrict={setSelectedDistrict}
        setSelectedPalika={setSelectedPalika}
        setSelectedWard={setSelectedWard}
      />

      <LocationMarker
        position={currentPosition}
        popupText="You are here"
      />
      <LocationMarker
        position={searchPosition}
        tooltipHeadText={searchedName}
      />
      <GetMyLocationButton
        setCurrentPosition={setCurrentPosition}
      />
      {process.env.MAP === "GOOGLE" ?
        <ReactLeafletGoogleLayer apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} type={'roadmap'} />
        :
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      }
    </MapContainer>
  );
};

export default MapView;



