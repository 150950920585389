import React from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import L from "leaflet";
import "./LocationMarker.css";

let mapPinIcon = L.icon({
    iconUrl: '/icons/map-pin.svg',

    iconSize: [35, 35],
    iconAnchor: [17, 35],
    popupAnchor: [0, -35],
});


const LocationMarker = ({
    position,
    popupText,
    tooltipHeadText,
    tooltipSubtext
}) => {
    return position === null ? null : (
        <Marker position={position} icon={mapPinIcon} >
            {popupText &&
                <Popup>
                    {popupText}
                </Popup>}
            <Tooltip
                direction="right"
                offset={[5, -14]}
                opacity={1}
                permanent
            >
                {tooltipHeadText && <div className="tooltip-head">{tooltipHeadText}</div>}
                {tooltipSubtext && <div className="tooltip-subtext">{tooltipSubtext}</div>}
            </Tooltip>

        </Marker>
    );
}

export default LocationMarker;