import React from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "./GetMyLocationButton.css";

const GetMyLocationButton = ({
    setCurrentPosition
}) => {
  const map = useMap();

  React.useEffect(() => {
    const locateControl = L.control({ position: "bottomright" });

    locateControl.onAdd = function (map) {
      const button = L.DomUtil.create("button", "get-my-location-button");
      const img = L.DomUtil.create('img');
      img.src = "/icons/get-my-location-red.png";
      img.style.width = '20px';
      img.style.height = '20px';
      button.appendChild(img);
    //   button.innerText = "My Location";
      button.onclick = function () {
        map.locate().on("locationfound", (e) => {
            const radius = e.accuracy;
            const circle = L.circle(e.latlng, radius);
            circle.addTo(map);
            setCurrentPosition(e.latlng);
            map.flyTo(e.latlng, 14);
        }).on("locationerror", (e) => {
          alert("Location access denied for this site. Please allow location access in settings and try again.");
        });
      };

      return button;
    };

    locateControl.addTo(map);

    return () => {
      locateControl.remove();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
};

export default GetMyLocationButton;