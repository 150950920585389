import { GeoJSON, useMap } from 'react-leaflet';
import "./style.css"
import { useEffect, useRef, useMemo } from 'react';

const CustomGeoJSON = ({
    layerName,
    data,
    style,
    onHover,
    onLayerClick,
    selectedProvince,
    setSelectedProvince,
    selectedDistrict,
    setSelectedDistrict,
    selectedPalika,
    setSelectedPalika,
    selectedWard,
    setSelectedWard,
}) => {
    const map = useMap();
    const geoJsonLayerRef = useRef(null);

    const filteredData = useMemo(() => {
        switch (layerName) {
            case "ProvinceLayer":
                return data;
            case "DistrictLayer":
                if (selectedProvince) return data?.features?.filter(feature => feature?.properties?.PROVINCE === selectedProvince);
                else return { type: "FeatureCollection", features: [] };
            case "PalikaLayer":
                if (selectedDistrict) return data?.features?.filter(feature => feature?.properties?.DISTRICT === selectedDistrict);
                else return { type: "FeatureCollection", features: [] };
            case "Wardlayer":
                if (selectedPalika) return data?.features?.filter(feature => feature?.properties?.PALIKA === selectedPalika);
                else return { type: "FeatureCollection", features: [] };
            default:
                return { type: "FeatureCollection", features: [] };
        }
    }, [data, selectedProvince, selectedDistrict, selectedPalika, layerName]);

    useEffect(() => {
        const layer = geoJsonLayerRef.current;
        if (layer) {
            layer.clearLayers().addData(filteredData);
            layer.setStyle(style);
        }
    }, [data, style, filteredData, selectedProvince]);

    const onEachFeature = (feature, layer) => {
        const hoverStyle = {
            fillOpacity: 0.3,
            opacity: 0.1,
            weight: 4,
            // fillColor : "#A876E9",
        };


        layer.on('mouseover', function (e) {
            this.setStyle(hoverStyle);
            e.target.getElement().style.transition = "fill-opacity 0.15s ease-in-out";
            onHover(feature?.properties);
        });

        layer.on('mouseout', function (e) {
            this.setStyle(style(feature));
            e.target.getElement().style.transition = "";
            onHover(feature?.properties);
        });

        layer.on('click', function () {
            map.flyToBounds(this.getBounds());
            switch (layerName) {
                case "ProvinceLayer":
                    if (setSelectedProvince) {
                        setSelectedProvince(feature?.properties?.PROVINCE);
                        setSelectedDistrict(null);
                        setSelectedPalika(null);
                        setSelectedWard(null);
                    };
                    break;
                case "DistrictLayer":
                    if (setSelectedDistrict) {
                        setSelectedDistrict(feature?.properties?.DISTRICT);
                        setSelectedPalika(null);
                        setSelectedWard(null);
                    }
                    break;
                case "PalikaLayer":
                    if (setSelectedPalika) {
                        setSelectedPalika(feature?.properties?.PALIKA);
                        setSelectedWard(null);
                    }
                    break;
                case "Wardlayer":
                    if (setSelectedWard) setSelectedWard(feature?.properties?.WARD);
                    break;
                default:
                    break;
            }
        });

    };

    return (
        <>
            <GeoJSON data={filteredData} ref={geoJsonLayerRef} style={style} onEachFeature={onEachFeature} />
        </>
    );
};

export default CustomGeoJSON;