import React, { useState, useEffect, useRef } from 'react';
import { Button, InputGroup, Input } from 'reactstrap';
import { useMap } from 'react-leaflet';
import { MapPin, Menu, Search as SearchIcon } from 'react-feather';
import { LatLngBounds, LatLng } from 'leaflet';

import "./style.css"

const Search = ({ toggleSidebar, setSearchPosition, setSearchedName }) => {
  const [address, setAddress] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const map = useMap();
  const searchDebounceRef = useRef(null);

  const fetchSuggestions = async (value) => {
    const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&limit=5&q=${value}`);
    const data = await response.json();
    if (data) setSuggestions(data?.filter((suggestion) => suggestion?.address?.country?.toLowerCase() === "nepal"));
  };

  useEffect(() => {
    if (address.length > 2) {
      // Clear the existing timeout
      if (searchDebounceRef.current) {
        clearTimeout(searchDebounceRef.current);
      }
      // Set a new timeout
      searchDebounceRef.current = setTimeout(() => {
        fetchSuggestions(address);
      }, 500); // Adjust the delay as needed
    } else {
      setSuggestions([]);
    }

    // Cleanup function to clear the timeout if the component unmounts
    return () => {
      if (searchDebounceRef.current) {
        clearTimeout(searchDebounceRef.current);
      }
    };
  }, [address]); // Depend on the address state

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setAddress(value);
  };

  const handleSelectSuggestion = (name, lat, lon, boundingbox) => {
    if (boundingbox) {
      const southWest = new LatLng(boundingbox[0], boundingbox[2]);
      const northEast = new LatLng(boundingbox[1], boundingbox[3]);
      const bounds = new LatLngBounds(southWest, northEast);
      map.flyToBounds(bounds);
    }
    else map.flyTo([lat, lon], 14);
    setSearchPosition([lat, lon]);
    setSearchedName(name);
    setSuggestions([]);
  };

  const getLeftBorderRadius = () => suggestions.length > 0 ? "24px 0px 0px 0px" : "80px 0px 0px 80px";
  const getRightBorderRadius = () => suggestions.length > 0 ? "0px 24px 0px 0px" : "0px 80px 80px 0px";

  // Function to determine box shadow based on suggestions presence
  const getBoxShadow = () => suggestions.length > 0 ? "0 0 2px rgb(0 0 0/20%), 0 -1px 0 rgb(0 0 0/2%)" : "0 2px 4px rgba(0,0,0,0.2),0 -1px 0px rgba(0,0,0,0.02)";

  return (
    <div className='ward-font' style={{ position: 'absolute', zIndex: 1000 }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (suggestions.length > 0) {
            handleSelectSuggestion(suggestions[0]?.name, suggestions[0]?.lat, suggestions[0]?.lon, suggestions[0]?.boundingbox);
            setAddress(suggestions[0]?.display_name);
            setSuggestions([]);
          }
        }}
      >
        <InputGroup className="m-3 mb-0" style={{
          height: "50px",
          maxWidth: "376px",
          borderRadius: "80px",
          boxShadow: getBoxShadow(),
        }}
        >
          <Button className='bg-white' style={{
            border: "none",
            borderRadius: getLeftBorderRadius(),

          }}
          >
            <Menu size="24px" color="#70757a" onClick={toggleSidebar} />
          </Button>
          <Input
            spellCheck="false"
            type="text"
            value={address}
            onChange={handleSearchChange}
            placeholder="Search for a place"
            className='ip ward-font border-0'
            style={{ boxShadow: "none" }}
          />
          <Button
            className='bg-white'
            style={{
              border: "none",
              borderColor: "none",
              color: "none",
              boxShadow: "none",
              borderRadius: getRightBorderRadius(),
            }}
          >
            <SearchIcon size="18px" color="#70757a" onClick={toggleSidebar} />
          </Button>
        </InputGroup>
      </form>
      {suggestions?.length > 0 && (
        <div className='sugg-list-comp border-top p-0 m-3 mt-0 mb-0 position-absolute bg-white' style={{ width: "100%", listStyleType: 'none', maxWidth: "376px", zIndex: 1000, borderRadius: "0px 0px 16px 16px" }}>
          <ul style={{ listStyleType: 'none', maxWidth: "376px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "0px", margin: "0px" }}>
            {suggestions?.map((suggestion, index,) => (
              <div key={index} className='d-flex align-items-center sugg-comp'>
                <div style={{minidth: "50px" }}>
                  <MapPin size="16px" color="#70757a" width="50px" />
                </div>
                <li className="sugg-list" key={index} onClick={() => handleSelectSuggestion(suggestion?.name, suggestion?.lat, suggestion?.lon, suggestion?.boundingbox)}>
                  <p style={{ fontSize: "14px", margin: "0px" }}>
                    {suggestion?.display_name}
                  </p>
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Search;
