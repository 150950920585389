// import './App.css';
import { useState } from 'react';
import MapView from './Components/Map';
import Sidebar from './Components/Sidebar';
import DeveloperInfoModal from './Components/Modal/DeveloperInfoModal';

const App = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  return (
    // <div className="bg-black">
    <div className='d-flex bg-dark'>
        <Sidebar 
          isOpen={sidebarToggle}
          toggle={() => setSidebarToggle(!sidebarToggle)}
        />
        <MapView toggleSidebar={() => setSidebarToggle(!sidebarToggle)} />
        <DeveloperInfoModal />
    </div>
  );
}

export default App;
