import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ListGroup, ListGroupItem } from 'reactstrap';
import Switch from "react-switch";
import { FiTool, FiUser, FiMail } from 'react-icons/fi';
import { FaTwitter } from "react-icons/fa";
import "./style.css";

const DeveloperInfoModal = () => {
  const [modal, setModal] = useState(true);
  const [language, setLanguage] = useState('nep');

  const toggle = () => setModal(!modal);

  const toggleLanguage = () => {
    setLanguage(language === 'eng' ? 'nep' : 'eng');
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size='md' style={{ minWidth: "30%" }}>
        <ModalHeader toggle={toggle}>
          {language === "eng" ?
            "Nepal Ward Map"
            : "नेपालको वडा नक्सा"}
        </ModalHeader>
        <ModalBody>
          <div className='d-flex justify-content-center align-items-center mb-3'>
            <img src="/icons/nepal-circular.png" alt='Nepal Flag' style={{ width: '25px', height: '25px', marginRight: '4px' }} />
            <h6 style={{ marginRight: "8px", marginBottom: "6px" }}>{language === "nep" ? "नेपाली" : "Nepali"}</h6>
            <Switch
            checked={language === "eng"}
            onChange={toggleLanguage}
            onColor="#86d3ff"
            offColor='#fa9696'
            onHandleColor="#2693e6"
            offHandleColor="#c91c1c"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0)"
            height={20}
            width={40}
            className="react-switch"
            id="material-switch"
          />
            <h6 style={{ marginLeft: "8px", marginBottom: "6px" }}>{language === "nep" ? "अंग्रेजी" : "English"}</h6>
            <img src="/icons/british-circular.png" alt='British Flag' style={{ width: '25px', height: '25px', marginLeft: '4px' }} />
          </div>
          {language === "eng" ?
            <p>Hello there! This is a map project I have been creating in my free time to facilitate the exploration of Nepal's administrative boundaries down to the ward level. It aims to provide an easy way to find Province, District, Palika, and Ward information for any location within Nepal.</p>
            : <p>
              नमस्ते! यो नेपालका प्रशासनिक सीमानाहरूलाई वार्ड स्तरसम्म पहिचान गर्न सजिलो बनाउने नक्शा परियोजना हो। यसले नेपाल भित्रको कुनै पनि स्थानको लागि प्रदेश, जिल्ला, पालिका, र वडाको जानकारी फेला पार्न सजिलो बनाउने उद्देश्य राख्दछ।
            </p>
          }
          {language === "eng" ?
            <p>Feel free to reach out for any questions or suggestions.</p>
            : <p>तपाईंसँग कुनै प्रश्न वा सुझाव छन् भने कृपया निःसंकोच सम्पर्क गर्नुहोस्।</p>
          }
          <ListGroup className='mb-1 mt-1'>
            <ListGroupItem>
              <FiTool /> <strong>{language === "eng" ? "Tools:" : "उपकरणहरू:"}</strong> Google Maps, Nominatim, OpenStreetMap
            </ListGroupItem>
            <ListGroupItem>
              <FiUser /> <strong>{language === "eng" ? "Sandesh Ghimire" : "सन्देश घिमिरे"}</strong> - {language === "eng" ? "Lalitpur, Nepal" : "ललितपुर, नेपाल"}
            </ListGroupItem>
            <ListGroupItem>
              <FiMail /> <strong>snadeghimire@gmail.com </strong>
            </ListGroupItem>
            <ListGroupItem>
              <FaTwitter color='#1DA1F2' /> <a href='https://twitter.com/sandace11' target='_blank' rel='noopener noreferrer'
                style={{ textDecoration: 'none' }}> @Sandace11</a>
            </ListGroupItem>
          </ListGroup>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeveloperInfoModal;
